/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import "babel-polyfill"

import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
// import $ from 'jquery'

require("@rails/ujs").start()

// loads the Icon plugin
UIkit.use(Icons)

// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.')

// import '@fortawesome/fontawesome-pro/js/all'

// import { library, dom } from '@fortawesome/fontawesome-svg-core'
// // import { faUsers as fasUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
// // import { faEye as fasEye } from '@fortawesome/pro-solid-svg-icons/faEye'
// // import { faTrash as fasTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
// // import { faFileMinus as fasFileMinus } from '@fortawesome/pro-solid-svg-icons/faFileMinus'
// // import { faFilePlus as fasFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus'
// // import { faPlusSquare as fasPlusSquare } from '@fortawesome/pro-solid-svg-icons/faPlusSquare'
// // import { faMapMarkerAlt as fasMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'
// // import { faPencilAlt as fasPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt'
// // import { faPaw as fasPaw } from '@fortawesome/pro-solid-svg-icons/faPaw'
// // import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
// // import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
// // import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
// // import { faTachometerAlt as fasTachometerAlt } from '@fortawesome/pro-solid-svg-icons/faTachometerAlt'
// // import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser'
// // import { faSignOut as fasSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut'
// // import { faNewspaper as fasNewspaper } from '@fortawesome/pro-solid-svg-icons/faNewspaper'
// // import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
// // import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
// // import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
// import { faUsers as fasUsers, faEye as fasEye, faTrash as fasTrash, faFileMinus as fasFileMinus, faFilePlus as fasFilePlus, faPlusSquare as fasPlusSquare, faMapMarkerAlt as fasMapMarkerAlt, faPencilAlt as fasPencilAlt, faPaw as fasPaw, faCircle as fasCircle, faSearch as fasSearch, faCaretDown as fasCaretDown, faTachometerAlt as fasTachometerAlt, faUser as fasUser, faSignOut as fasSignOut, faNewspaper as fasNewspaper, faExclamationTriangle as fasExclamationTriangle, faCheckCircle as fasCheckCircle, faInfoCircle as fasInfoCircle, faPencil as fasPencil, faFileAlt as fasFileAlt, faFrown as fasFrown } from '@fortawesome/pro-solid-svg-icons'
//
// import { faEdit as farEdit, faChevronDoubleLeft as farChevronDoubleLeft, faChevronDoubleRight as farChevronDoubleRight, faChevronRight as farChevronRight, faChevronLeft as farChevronLeft, faUndoAlt as farUndoAlt, faHeart as farHeart, faShoppingBag as farShoppingBag, faList as farList } from '@fortawesome/pro-regular-svg-icons'
// // import { faEdit as farEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
// // import { faChevronDoubleLeft as farChevronDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft'
// // import { faChevronDoubleRight as farChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight'
// // import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
// // import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
// // import { faUndoAlt as farUndoAlt } from '@fortawesome/pro-regular-svg-icons/faUndoAlt'
// // import { faHeart as farHeart } from '@fortawesome/pro-regular-svg-icons/faHeart'
// // import { faShoppingBag as farShoppingBag } from '@fortawesome/pro-regular-svg-icons/faShoppingBag'
// // import { faList as farList } from '@fortawesome/pro-regular-svg-icons/faList'
//
// import { faInfo as falInfo, faNewspaper as falNewspaper, faCalendarAlt as falCalendarAlt, faBell as falBell, faTachometerAlt as falTachometerAlt, faAngleRight as falAngleRight, faFileAlt as falFileAlt, faFrown as falFrown } from '@fortawesome/pro-light-svg-icons'
// // import { faInfo as falInfo } from '@fortawesome/pro-light-svg-icons/faInfo'
// // import { faNewspaper as falNewspaper } from '@fortawesome/pro-light-svg-icons/faNewspaper'
// // import { faCalendarAlt as falCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt'
// // import { faBell as falBell } from '@fortawesome/pro-light-svg-icons/faBell'
// // import { faTachometerAlt as falTachometerAlt } from '@fortawesome/pro-light-svg-icons/faTachometerAlt'
// // import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
// // import { faFileAlt as falFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt'
//
// library.add(
//   fasUsers, fasEye, fasTrash, fasFileMinus, fasFilePlus, fasPlusSquare, fasMapMarkerAlt, fasPencilAlt, fasPaw, fasCircle, fasSearch, fasCaretDown, fasTachometerAlt, fasUser, fasSignOut, fasNewspaper, fasExclamationTriangle, fasCheckCircle, fasInfoCircle, fasPencil, fasFileAlt, fasFrown,
//   farEdit, farChevronDoubleLeft, farChevronDoubleRight, farChevronRight, farChevronLeft, farUndoAlt, farHeart, farShoppingBag, farList, falInfo, falNewspaper, falCalendarAlt, falBell, falTachometerAlt, falAngleRight, falFileAlt, falFrown)
//
// dom.watch()

import 'front/card_collection'
import 'front/stripe'
import 'utils/confirm_form_leave'
