import $ from 'jquery'

$.when( $.ready ).then ->

  $('fieldset.card_collection').find('input[type="checkbox"]:checked + label').find('svg.svg-inline--fa').attr('data-icon', 'check-square').attr('data-prefix', 'fas')

  $('fieldset.card_collection').find('input[type="checkbox"]').on 'change', ->
    $this = $(@)
    $this.next('label').find('svg.svg-inline--fa').attr 'data-icon', if $this.is(':checked') then 'check-square' else 'square'
      .attr 'data-prefix', if $this.is(':checked') then 'fas' else 'fal'
