import $ from 'jquery'

$.when( $.ready ).then ->
  $('form').one 'change', 'input, select', (e) ->
    $form = $(e.currentTarget.form)
    $form.addClass('touched').data('confirmLeave', true)

    $form.find('button[type="submit"], input[type="submit"], .skip-confirm-leave').on 'click', ->
        $(window).off 'beforeunload'

    $(window).on 'beforeunload', ->
      setTimeout ->
        setTimeout ->
        , 2000
      , 1
      'Êtes-vous sûr de vouloir quitter la page ? Vos informations seront perdues.'
