import $ from 'jquery'
import Rails from '@rails/ujs'



previousCardBrand = null

registerElements = (elements) ->

  for key, element of elements
    element.on 'change', ({elementType, error, brand}) ->
      displayError = $("##{elementType}-errors")
      icon = displayError.prev('.uk-inline').find('svg.svg-inline--fa')

      if (error)
        displayError.text error.message
                    .removeAttr 'hidden'
      else
        displayError.text ''
                    .attr 'hidden', true

      if elementType is 'cardNumber' and brand isnt previousCardBrand
        previousCardBrand = brand
        switch brand
          when 'visa'
            icon.attr 'data-icon', 'cc-visa'
                .attr 'data-prefix', 'fab'
          when 'mastercard'
            icon.attr 'data-icon', 'cc-mastercard'
                .attr 'data-prefix', 'fab'
          else
            icon.attr 'data-icon', 'credit-card-front'
                .attr 'data-prefix', 'fal'


stripeTokenHandler = (token) ->
  # Insert the token ID into the form so it gets submitted to the server
  form = document.getElementById('payment-form')
  hiddenInput = document.createElement('input')
  hiddenInput.setAttribute('type', 'hidden')
  hiddenInput.setAttribute('name', 'stripeToken')
  hiddenInput.setAttribute('value', token.id)
  form.appendChild(hiddenInput)

  # Submit the form
  form.submit()

$.when( $.ready ).then ->
  if Stripe?
    stripe = Stripe('pk_test_Xrs93gqfIzbkVtrHSMEE0DfZ')
    elements = stripe.elements()

    # Custom styling can be passed to options when creating an Element.
    style =
      base:
        color: '#32325d'
        lineHeight: '18px'
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
        fontSmoothing: 'antialiased'
        fontSize: '16px'
        '::placeholder':
          color: '#aab7c4'
      # invalid:
        # color: '#fa755a'
        # iconColor: '#fa755a'

    # # Create an instance of the card Element.
    # card = elements.create('card', {style})
    #
    # # Add an instance of the card Element into the `card-element` <div>.
    # card.mount('#card-element')

    cardNumber = elements.create('cardNumber', {style})
    cardNumber.mount('#cardNumber')

    cardExpiry = elements.create('cardExpiry', {style})
    cardExpiry.mount('#cardExpiry')

    cardCvc = elements.create('cardCvc', {style})
    cardCvc.mount('#cardCvc')

    registerElements
      number: cardNumber
      expiry: cardExpiry
      cvc: cardCvc

    # Create a token or display an error when the form is submitted.
    form = $('form#payment-form')
    form.on 'submit', (event) ->
      event.preventDefault()

      {token, error} = await stripe.createToken(cardNumber)

      if (error)
        # Inform the customer that there was an error.
        errorElement = document.getElementById('cardNumber-errors')
        errorElement.textContent = error.message

        buttonElement = form.find('button[type="submit"]').get(0)
        setTimeout ->
          $button = $('form#payment-form').find('button[type="submit"]').first()
          Rails.enableElement($button.get(0))
        , 1000
      else
        # Send the token to your server.
        stripeTokenHandler(token)
